<template>
  <div class="wgcna">
    <div class="wgcna-bg" v-if="isSearch">
      <p class="wgcna-bg-title">miRNA-Seq WGCNA</p>
      <p>Co-Expression network build by WGCNA</p>
      <SearchInput v-model="searchValue" :data-source="searchDataSource" style="width: 40rem"
                   placeholder="Example:Key words eg. liver Project ID eg.GSE117441"
                   @search="handleSearch"/>
    </div>
    <div class="wgcna-res" v-else>
      <SearchInput v-model="searchValue" :data-source="searchDataSource"
                   placeholder="Example:Key words eg. liver Project ID eg.GSE117441"
                   @search="handleSearch"/>

      <div v-for="(item,index) in pageData" :key="index" class="wgcna-res-item">
        <div class="p" @click="query(item)">
          <div v-for="(sp,i) in item.gse_acc.split(searchValue)" :key="i">
            <span class="green">{{ sp }}</span>
            <span class="red"
                  v-if="i !== item.gse_acc.split(searchValue).length - 1">{{ searchValue }}</span>
          </div>
        </div>
        <div class="con">{{ item.overall_design }}</div>
      </div>

      <a-pagination v-if="projectData.length !== 0" v-model="pagination.current" :total="projectData.length" :pageSize="pagination.pageSize"
                    show-less-items/>
    </div>
  </div>
</template>

<script>
import {fetchMiRNAProjectList} from "@/request/niu_api";
import SearchInput from "@/components/SearchInput/index.vue";

export default {
  components: {SearchInput},
  data() {
    return {
      isSearch: true,
      searchValue: "",
      searchDataSource:[],
      projectData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal(total) {
          return `Total ${total} items`
        },
      },
    };
  },
  computed: {
    pageData() {
      const offset = (this.pagination.current - 1) * this.pagination.pageSize;
      return this.projectData.slice(offset, offset + this.pagination.pageSize);
    }
  },
  created() {
    if(this.$route.query.search){
      this.searchValue = this.$route.query.search;
      this.isSearch = false;
      this.handleSearch();
    }

    fetchMiRNAProjectList({
      "search": "",
      "page": 1,
      "limit": 1000,
      "sort": "gse_acc",
      "order": "asc"
    }).then(res => {
      if (res.code === 0) {
        this.searchDataSource = res.data.data.map(item => ({
          ...item,
          key:item.gse_acc,
          value:item.gse_acc
        }));
      } else {
        this.$message.error(res.errMsg)
      }
    })
  },
  mounted() {
    // 监听回车事件
    document.onkeydown = (e) => {
      const key = window.event.keyCode;
      if (key === 13) {
        this.handleSearch();
      }
    };
  },
  methods: {
    handleSearch() {
      if (this.searchValue === "") {
        this.$message.warn("搜索内容不能为空")
        return;
      }
      fetchMiRNAProjectList({
        "search": this.searchValue,
        "page": 1,
        "limit": 1000,
        "sort": "gse_acc",
        "order": "asc"
      }).then(res => {
        if (res.code === 0) {
          this.isSearch = false;
          this.projectData = res.data.data;
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    query(record) {
      window.localStorage.setItem("current_mirna_wgcna_data", JSON.stringify(record));
      this.$router.push({path: '/miRNA-WGCNAQueryResult', query: {gse_acc: record.gse_acc}});
    }
  },
};
</script>

<style lang="scss" scoped>
.wgcna {
  padding: 1.3rem 8rem;
  width: 100vw;
  min-height: calc(100vh - 5rem - 64px);

  &-bg {
    width: 100%;
    height: calc(100vh - 7.8rem - 64px);
    background-image: url("/images/co/searchBack.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 19px;
      color: #666;
    }

    &-title {
      font-size: 27px !important;
      font-weight: 600;
      color: #333333;
      line-height: 31px;
    }
  }

  &-res {
    width: 100%;
    min-height: calc(100vh - 7.8rem - 64px);
    background: #fff;
    padding: 2rem;

    .wgcna-search {
      width: 100%;
    }

    &-item {
      margin-top: 1.3rem;

      .p {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: 0.2rem;
        cursor: pointer;

        & > div {
          display: inline-block;

          .green {
            color: #097F35;
            text-decoration: underline;
          }

          .red {
            color: #E05B5B;
            text-decoration: underline;
          }
        }
      }

      .con {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 1.5rem;
      }
    }

    ::v-deep .ant-pagination {
      margin-top: 2rem;
      text-align: center;
    }
  }
}
</style>
